/*! Copyright 2021 Low Associates, Inc. */

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import vueDebounce from "vue-debounce";

import VueScrollTo from "vue-scrollto";

// import styles
import "./assets/sass/styles.scss";
import { toSlashDateFormat } from "./utilities";

const isProd = process.env.NODE_ENV === "production";

// redirect HTTP to HTTPS
if (isProd) {
  if (location.protocol !== "https:") {
    location.protocol = "https:";
  }
}

// Google Analytics v4
Vue.use(
  VueGtag,
  {
    config: {
      id: "G-3728CLJ2PX",
      cookie_domain: "none",
    },
    disableScriptLoad: !isProd,
  },
  router
);

// Vue ScrollTo
Vue.use(VueScrollTo, {
  offset: function() {
    const el = document.getElementById("parcel-toolbar");
    if (el) {
      return 0 - el.offsetHeight;
    } else {
      return 0;
    }
  },
  duration: 250,
});

Vue.config.productionTip = false;

/*
Vue.config.errorHandler = function(err, vm, info) {
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
  // JS/Vue errors tracked in Google Analytics (see "autoTracking" above)
};

// log all axios errors to console
axios.interceptors.response.use(
  function(response) {
    // TODO: track API calls in Google Analytics (including search terms)
    return response; // 2xx status code
  },
  function(error) {
    // TODO: track API call errors in Google Analytics (include params and error message)
    // if (error.response) {
    //   // The request was made and the server responded with a status code
    //   // that falls out of the range of 2xx
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   console.log(error.response.headers);
    // } else if (error.request) {
    //   // The request was made but no response was received
    //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //   // http.ClientRequest in node.js
    //   console.log(error.request);
    // } else {
    //   // Something happened in setting up the request that triggered an Error
    //   console.log("Error", error.message);
    // }
    // console.log(error.config);
    return Promise.reject(error);
  }
);
*/

Vue.use(VueAxios, axios);

Vue.filter("currency", function(value: number, showCents: boolean = true) {
  if (value == null || !isFinite(value) || isNaN(value)) return value;
  if (value < 0) {
    return (
      "($" +
      Math.abs(value)
        .toFixed(showCents ? 2 : 0)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") +
      ")"
    );
  } else {
    return (
      "$" +
      value
        .toFixed(showCents ? 2 : 0)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    );
  }
});

Vue.filter("toDate", toSlashDateFormat);

Vue.use(vueDebounce);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
