export function formatDollarAmount(amount: number): string {
  if (!amount) return "$0.00";
  let isNeg;
  if (amount < 0) {
    isNeg = true;
    amount = Math.abs(amount);
  }
  let strAmount = amount.toString();
  let result = "";
  let count = 0;
  let index = strAmount.length - 1;
  const dotIndex = strAmount.indexOf(".");
  if (dotIndex >= 0) {
    while (strAmount.length - dotIndex < 3) {
      strAmount += "0";
    }
    if (strAmount.length - dotIndex > 3) {
      strAmount = strAmount.slice(0, dotIndex + 3);
    }
    index = strAmount.length - 4;
  } else {
    strAmount += ".00";
  }

  result = strAmount.slice(index + 1);
  while (index >= 0) {
    if (count < 3) {
      result = strAmount[index] + result;
      count += 1;
      index -= 1;
    } else if (count === 3) {
      result = "," + result;
      count = 0;
    }
  }

  if (isNeg) {
    result = "-" + result;
  }

  return "$" + result;
}

export function deepCopy(obj: any): any {
  if (obj === null || typeof obj !== "object") return obj;
  const copy = new obj.constructor();
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    copy[keys[i]] = deepCopy(obj[keys[i]]);
  }
  return copy;
}

export function toSlashDateFormat(value: string) {
  if (!value) return "";
  const padNumberWithZeros = function(num: number, len: number) {
    let s = "" + num;
    while (s.length < len) {
      s = "0" + s;
    }
    return s;
  };
  if (!value) return "N/A";

  const splitArr = value.split("-");
  const suffix = splitArr[splitArr.length - 1].replace(")", "");
  const epochTime = parseInt(value.replace("/Date(", ""));
  const offset = parseInt(suffix) / 100;
  const tzDifference = 3600000 * offset;
  const timeToDisplayMs = epochTime - tzDifference;
  const d = new Date(timeToDisplayMs);
  return (
    padNumberWithZeros(d.getUTCMonth() + 1, 2) +
    "/" +
    padNumberWithZeros(d.getUTCDate(), 2) +
    "/" +
    d.getUTCFullYear()
  );
}
