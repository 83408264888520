import Vue from "vue";
import VueRouter, { Route } from "vue-router";
import Counties from "../../config/counties";

type County = {
  active: boolean;
  slug: string;
  corpCode: string;
};

function getCounty(params: any) {
  if (params.slug) {
    return Counties.find(
      (county: County) =>
        county.active && county.slug.toLowerCase() === params.slug.toLowerCase()
    );
  } else if (params.corpCode) {
    return Counties.find(
      (county: County) =>
        county.active &&
        county.corpCode.toLowerCase() === params.corpCode.toLowerCase()
    );
  }
}

Vue.use(VueRouter);

const routes = [
  {
    component: () => import("@/views/HomeView.vue"),
    name: "home",
    path: "/(index.html)?",
    props: {
      counties: Counties.filter(function(county) {
        return county.active;
      }),
    },
  },
  {
    component: () => import("../views/CountyView.vue"),
    name: "county",
    path: "/:slug(.*county)(/index.html)?",
    props: (route: Route) => ({
      county: getCounty(route.params),
    }),
    beforeEnter: (to: Route, from: Route, next: any) => {
      // redirect old "PropertyView" route (e.g. "/allencounty/#/PropertyView/?dup=1812191")
      if (to.hash.indexOf("/PropertyView") > 0 && to.hash.indexOf("dup=") > 0) {
        const hashParams = JSON.parse(
          '{"' +
            to.hash
              .split("?")[1]
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}',
          function(key, value) {
            return key === "" ? value : decodeURIComponent(value);
          }
        );
        next({
          name: "parcel",
          params: {
            slug: to.params.slug.toLowerCase(),
            dup: hashParams.dup,
            payYear: new Date().getFullYear(),
          },
        });
        // redirect old Search route (e.g. "/allencounty/#/Search/-/400/-/-")
      } else if (to.hash.indexOf("/Search") > 0) {
        const hashParams = to.hash.split("/");
        const newQueryMap = new Map();
        newQueryMap.set("ownerName", hashParams[2]);
        newQueryMap.set("address", hashParams[3]);
        newQueryMap.set("parcelNumber", hashParams[4]);
        newQueryMap.set("duplicateNumber", hashParams[5]);
        newQueryMap.forEach(function(value, key) {
          if (value == "-" || !value) {
            newQueryMap.delete(key);
          }
        });
        next({
          name: "county",
          params: {
            slug: to.params.slug.toLowerCase(),
          },
          query: Object.fromEntries(newQueryMap),
        });
      } else {
        next();
      }
    },
  },
  {
    // redirect old Print route "/print/#/POR/by-duplicate/987261/2020"
    path: "/print/",
    hash: "#/:corpCode/by-duplicate/:dup/:payYear",
    redirect: (to: Route) => {
      const hashParams = to.hash.split("/");
      const county = getCounty({ corpCode: hashParams[1] });
      if (county) {
        return {
          path:
            county.slug.toLowerCase() +
            "/" +
            hashParams[3] +
            "-" +
            hashParams[4],
          hash: "",
        };
      } else {
        return { name: "home" };
      }
    },
  },
  {
    // redirect old Print route "/POR/by-duplicate/987261/2020"
    path: "/:corpCode/by-duplicate/:dup/:payYear",
    redirect: (to: Route) => {
      const county = getCounty(to.params);
      if (county) {
        return {
          path:
            county.slug.toLowerCase() +
            "/" +
            to.params.dup +
            "-" +
            to.params.payYear,
        };
      } else {
        return { name: "home" };
      }
    },
  },
  {
    component: () => import("../views/ParcelView.vue"),
    name: "parcelFuture",
    path: "/:slug(.*county)/:dup-:payYear(.*-future)",
    props: (route: Route) => ({
      county: getCounty(route.params),
      isFuture: true,
    }),
  },
  {
    component: () => import("../views/ParcelView.vue"),
    name: "parcel",
    path: "/:slug(.*county)/:dup-:payYear",
    props: (route: Route) => ({
      county: getCounty(route.params),
    }),
  },
  {
    component: () => import("../views/ParcelView.vue"),
    name: "parcelCurrentYear",
    path: "/:slug(.*county)/:dup",
    props: (route: Route) => ({
      county: getCounty(route.params),
    }),
  },
  // {
  //   component: () => import("../views/About.vue"),
  //   name: "about",
  //   path: "/about",
  // },
  {
    // component: () => import("../views/NotFound.vue"),
    component: () => import("../views/HomeView.vue"),
    name: "catchAll",
    path: "/:catchAll(.*)",
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL.replace(/\/$/, ""), // no trailing slash
  mode: "history",
  routes,
});

export default router;
